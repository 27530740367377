import { urls } from "../api/api.urls";
import makeRequest from "../api/make.request";
import makeUploadRequest from "../api/make.upload";

export default class AdminService {
  static async uploadCsvData(examId: any, shiftId: any, formData: any) {
    return makeUploadRequest(
      urls.admin.uploadCsvData + "/" + examId + "/" + shiftId,
      "post",
      formData
    );
  }

  static async getInternalId() {
    return await makeRequest(urls.admin.getInternalId, "get");
  }

  static async getShiftData(examId: string) {
    return await makeRequest(urls.admin.getShiftData + "/" + examId, "get");
  }

  static async getMappings(examId: any, shiftId: any) {
    return await makeRequest(
      urls.admin.getMappings + "/" + examId + "/" + shiftId,
      "get"
    );
  }

  static async updateMappingData(examId: any, shiftId: any, payload: any) {
    return await makeRequest(
      urls.admin.updateMappingData + "/" + examId + "/" + shiftId,
      "put",
      payload
    );
  }

  static async getColumnData(examId: any, shiftId: any) {
    return await makeRequest(
      urls.admin.getColumnData + "/" + examId + "/" + shiftId,
      "get"
    );
  }

  static async mapInterIdsWithSheetIds(
    examId: any,
    shiftId: any,
    payload: any
  ) {
    return await makeRequest(
      urls.admin.mapInterIdsWithSheetIds + "/" + examId + "/" + shiftId,
      "post",
      payload
    );
  }

  static async createAutoInvigilatorForCenter(
    examId: any,
    shiftId: any,
    payload: any
  ) {
    return await makeRequest(
      urls.admin.createAutoInvigilatorForCenter + "/" + examId + "/" + shiftId,
      "post",
      payload
    );
  }

  static async confirmInvigilator(examId: any, shiftId: any, payload: any) {
    return await makeRequest(
      urls.admin.confirmInvigilator + "/" + examId + "/" + shiftId,
      "post",
      payload
    );
  }

  static async updateInvigilatorsCounts(
    examId: any,
    shiftId: any,
    payload: any
  ) {
    return await makeRequest(
      urls.admin.updateInvigilatorsCounts + "/" + examId + "/" + shiftId,
      "put",
      payload
    );
  }

  static async getAssignedInviliatorCount(examId: any, shiftId: any) {
    return await makeRequest(
      urls.admin.getAssignedInviliatorCount + "/" + examId + "/" + shiftId,
      "get"
    );
  }

  static async getAllUploadedFiles(examId: any, shiftId: any) {
    return await makeRequest(
      urls.admin.getAllUploadedFiles + "/" + examId + "/" + shiftId,
      "get"
    );
  }

  static async createCSVForInvigilators(examId: any, shiftId: any) {
    return await makeRequest(
      urls.admin.createCSVForInvigilators + "/" + examId + "/" + shiftId,
      "post"
    );
  }

  static async uploadInvigilatorSheetForPasswordCreation(
    examId: any,
    shiftId: any,
    payload: any
  ) {
    return await makeRequest(
      urls.admin.uploadInvigilatorSheetForPasswordCreation +
      "/" +
      examId +
      "/" +
      shiftId,
      "post",
      payload
    );
  }

  static async getAllEnumsForVerification() {
    return await makeRequest(urls.exam.getAllEnumsForVerfication, "get");
  }

  static async getExamSetting(examId: any) {
    return await makeRequest(urls.exam.getExamSetting + "/" + examId, "get");
  }

  static async updateSettings(examId: any, payload: any) {
    return await makeRequest(
      urls.exam.updateExamSettings + "/" + examId,
      "put",
      payload
    );
  }

  static async getAllExamName() {
    return await makeRequest(urls.admin.getAllExamName, "get");
  }

  static async getCSRReport(examId: string) {
    return await makeRequest(urls.admin.getCSRReport + "/" + examId, "get");
  }

  static async getPastUploads(examId: any, shiftId: any) {
    return await makeRequest(
      urls.admin.getPastUploads + "/" + examId + "/" + shiftId,
      "get"
    );
  }
  static async getVerificationData(examId: any, shiftId: any) {
    return await makeRequest(
      urls.admin.getVerificationData + "/" + examId + "/" + shiftId,
      "get"
    );
  }
  static async getCountData(examId: any, shiftId: any) {
    return await makeRequest(
      urls.admin.getCountData + "/" + examId + "/" + shiftId,
      "get"
    );
  }

  static async getExamStates(examId: any, shiftId: any) {
    return await makeRequest(
      urls.admin.getStates + "/" + examId + "/" + shiftId,
      "get"
    );
  }
  static async getExamDistricts(examId: any, shiftId: any) {
    return await makeRequest(
      urls.admin.getDistricts + "/" + examId + "/" + shiftId,
      "get"
    );
  }
  static async getExamZones(examId: any, shiftId: any) {
    return await makeRequest(
      urls.admin.getZones + "/" + examId + "/" + shiftId,
      "get"
    );
  }
  static async getExamCenters(examId: any, shiftId: any) {
    return await makeRequest(
      urls.admin.getCenters + "/" + examId + "/" + shiftId,
      "get"
    );
  }

  static async getStatsForFilterCombination(examId: any, shiftId: any, payload: any) {
    return await makeRequest(urls.admin.getUsers + "/" + examId + "/" + shiftId, "post", payload);
  }

  static async getAllClients(examId: any) {
    return await makeRequest(urls.admin.getAllClient + "/" + examId, "get")
  }

  static async createClient(examId: any) {
    return await makeRequest(urls.admin.createClient + "/" + examId, "post")
  }

  static async resetClientPassword(examId: any, username: any) {
    return await makeRequest(urls.admin.resetClientPassword + "/" + examId + "/" + username, "put")
  }

  static async deleteClient(clientId: any) {
    return await makeRequest(urls.admin.deleteClient + "/" + clientId, "delete")
  }

  static async deleteShift(examId: any, shiftId: any) {
    return await makeRequest(urls.admin.deleteShift + "/" + examId + "/" + shiftId, "delete")
  }

  static async checkIfFieldsExist(examId: any, shiftId: any) {
    return await makeRequest(urls.admin.checkIfFieldsExist + "/" + examId + "/" + shiftId, "get")
  }

  static async downloadVerifiedUsers(examId: any, shiftId: any) {
    return await makeRequest(urls.admin.downloadVerifiedUsers + "/" + examId + "/" + shiftId, "get")
  }

  static async createStudent(studentData: any, examId: any, shiftId: any) {
    return await makeUploadRequest(urls.admin.createStudent + "/" + examId + "/" + shiftId, "post", studentData)
  }

  static async toggleExamOffline(examId: any) {
    return await makeRequest(urls.admin.toggleOfflineExam + "/" + examId, "put")
  }

  static async  getInstructorByExamShift(examId: any, shiftId: any) {
    return await makeRequest(urls.admin.getInstructorByExamShift + "/" + examId + "/" + shiftId, "get")
  }

  static async getStatsByExamShift(examId: any, shiftId: any) {
    return await makeRequest(urls.admin.getStatsByExamShift + "/" + examId + "/" + shiftId, "get")
  }

  static async getInvigilatorsStats(examId: string, shiftId: string, params: string) {
    return await makeRequest(urls.stats.getInvigilatorsStats + "/" + examId + "/" + shiftId + params, "get")
  }

}