import React, { useEffect, useState } from 'react'
import { Button, Card, Container, Navbar, Spinner, Tab, Tabs } from 'react-bootstrap'
import Select from 'react-select'
import AdminService from '../../../services/admin.service';
import { makeParams } from '../../../api/make.request';
import { UsersCard } from '../../../components/Card/Statistics.card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUser } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../../components/table/Invigilator.table';

export default function InvigilatorStatsIndex() {
    const [exam, setExam] = useState<any[]>([]);
    const [shifts, setShifts] = useState<any[]>([]);
    const [centers, setCenters] = useState<any[]>([]);
    const [selectedExam, setSelectedExam] = useState<any>();
    const [selectedShift, setSelectedShift] = useState<any>();
    const [selectedCenters, setSelectedCenters] = useState<any>();
    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);


    const getCenters = async () => {
        await AdminService.getExamCenters(selectedExam.value, selectedShift.value).then(res => {
            if (res.status === 200) {
                setCenters(res.data.map((dist: any) => {
                    return {
                        value: dist,
                        label: dist
                    }
                }))
            }
        })
    }

    const getExams = async () => {
        await AdminService.getAllExamName().then((res) => {
            if (res.status === 200) {
                setExam(res.data.data.examName.map((data: any) => {
                    return {
                        value: data._id,
                        label: data.name
                    }
                }))
            }
        })
    }


    const getShiftData = async () => {
        await AdminService.getShiftData(selectedExam.value)
            .then((res) => {
                if (res.status === 200) {
                    setShifts(
                        res.data.data.shifts.map((data: any) => {
                            return {
                                value: data._id,
                                label: data.shiftName,
                            };
                        })
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    async function getData() {
        const params = makeParams([
            { index: "centerId", value: selectedCenters?.value },
        ]);

        setLoading(true);
        await AdminService.getInvigilatorsStats(selectedExam.value, selectedShift.value, params)
            .then(res => {
                if (res.status === 200) {
                    setData(res.data)
                }
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (selectedShift?.value) {
            getCenters();
        }
    }, [selectedShift]);

    useEffect(() => {
        if (selectedExam?.value) {
            getShiftData();
        }
    }, [selectedExam]);

    useEffect(() => {
        getExams();
    }, []);
    return (
        <>
            <Navbar bg="light" expand="lg" className="navbarWrapper">
                <Container fluid>
                    <Navbar.Brand href="#">Live Record</Navbar.Brand>
                    <div className="d-flex gap-4">
                        <Select
                            options={exam}
                            onChange={setSelectedExam}
                            value={selectedExam}
                            placeholder="Select Exam"
                        />
                        <Select
                            options={shifts}
                            onChange={setSelectedShift}
                            value={selectedShift}
                            placeholder="Select Shift"
                        />
                        <Select
                            options={centers}
                            onChange={setSelectedCenters}
                            value={selectedCenters}
                            placeholder="Select Center"
                        />
                        <Button onClick={getData} disabled={loading}>
                            {
                                loading ? <span>Getting Data...<Spinner animation="border" size="sm" /></span> : <span>Get Data</span>
                            }
                        </Button>
                    </div>
                </Container>
            </Navbar>

            <div>
                <Card className="shadow-sm p-3 mb-3 bg-white rounded">
                    <Card.Body>
                        <Tabs
                            defaultActiveKey={"completedDownloadInvigilators"}
                        >
                            <Tab eventKey="completedDownloadInvigilators" title="Download Completed">
                                <div className="mt-2">
                                    <UsersCard
                                        reactIcon={<FontAwesomeIcon icon={faDownload} className="fs-1 text-success" />}
                                        header="Count"
                                        statsValue={data?.completedDownloadInvigilators?.count || 0}
                                    />
                                    <div className="card mt-3 p-2 shadow border-0">
                                        <DataTable data={data?.completedDownloadInvigilators?.invigilators} loading={loading} />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="pendingDownloadInvigilators" title="Download Pending">
                                <div className="mt-2">
                                    <UsersCard
                                        reactIcon={
                                            <FontAwesomeIcon icon={faDownload} className="fs-1 text-warning" />
                                        }
                                        header="Count"
                                        statsValue={data?.pendingDownloadInvigilators?.count || 0}
                                    />
                                </div>
                                <div className="card mt-3 p-2 shadow border-0">
                                    <DataTable data={data?.pendingDownloadInvigilators?.invigilators} loading={loading} />
                                </div>
                            </Tab>
                            <Tab eventKey="inProgressDownloadInvigilators" title="Download in-progress">
                                <div className="mt-2">
                                    <UsersCard
                                        reactIcon={
                                            <FontAwesomeIcon icon={faDownload} className="fs-1 text-primary" />
                                        }
                                        header="Count"
                                        statsValue={data?.inProgressDownloadInvigilators?.count || 0}
                                    />
                                </div>
                                <div className="card mt-3 p-2 shadow border-0">
                                    <DataTable data={data?.inProgressDownloadInvigilators?.invigilators} loading={loading} />
                                </div>
                            </Tab>
                            <Tab eventKey="loggedInInvigilators" title="Loged-in Invigilators">
                                <div className="mt-2">
                                    <UsersCard
                                        reactIcon={
                                            <FontAwesomeIcon icon={faUser} className="fs-1 text-success" />
                                        }
                                        header="Count"
                                        statsValue={data?.loggedInInvigilators?.count || 0}
                                    />
                                </div>
                                <div className="card mt-3 p-2 shadow border-0">
                                    <DataTable data={data?.loggedInInvigilators?.invigilators} loading={loading} />
                                </div>
                            </Tab>
                            <Tab eventKey="totalInvigilators" title="Total Invigilators">
                                <div className="mt-2">
                                    <UsersCard
                                        reactIcon={
                                            <FontAwesomeIcon icon={faUser} className="fs-1" />
                                        }
                                        header="Count"
                                        statsValue={data?.totalInvigilators?.count || 0}
                                    />
                                </div>
                                <div className="card mt-3 p-2 shadow border-0">
                                    <DataTable data={data?.totalInvigilators?.invigilators} loading={loading} />
                                </div>
                            </Tab>
                        </Tabs>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}