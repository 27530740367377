import React from "react";
import { Table, Spinner, Container } from "react-bootstrap";
import { formatDate } from "../helper/Date";

interface Props {
    data: any;
    loading: boolean;
}

const DataTable: React.FC<Props> = ({ data, loading }) => {
    return (
        <>
            {loading ? (
                <div className="d-flex justify-content-center my-4">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <Table striped hover bordered size="sm" responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Center</th>
                            <th>User Name</th>
                            <th>Email</th>
                            {/* <th>Exam ID</th>
                            <th>Shift ID</th> */}
                            <th>Last Synced</th>
                            <th>Last Download</th>
                            <th>Download %</th>
                            <th>Upload %</th>
                            <th>Last Login</th>
                            <th>Last Logout</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.length > 0 ? (
                            data?.map((item: any, index: number) => (
                                <tr key={item?._id}>
                                    <td>{index + 1}</td>
                                    <td>{item?.centerId?.[0]}</td>
                                    <td>{item?.userName}</td>
                                    <td>{item?.emailId}</td>
                                    {/* <td>{item?.examId}</td>
                                    <td>{item?.shiftId}</td> */}
                                    <td className="text-muted fst-italic">
                                        {item?.lastSyncedOn
                                            ? formatDate(item?.lastSyncedOn)
                                            : "N/A"}
                                    </td>
                                    <td className="text-muted fst-italic">
                                        {item?.lastDownloadAt
                                            ? formatDate(item?.lastDownloadAt)
                                            : "N/A"}
                                    </td>
                                    <td>
                                        {item?.meta.downloadStats.percentage >= 0
                                            ?
                                            <div className="text-nowrap">
                                                <span>{item?.meta.downloadStats.percentage}%</span><br />
                                                <span className="xcn-stats-card-footer text-muted mt-auto px-3 bg-transparent">Synced on: {formatDate(item?.meta.downloadStats.syncedOn)}</span>
                                            </div>
                                            : "N/A"}
                                    </td>
                                    <td>
                                        {item?.meta.uploadStats.percentage >= 0
                                            ?
                                            <div className="text-nowrap">
                                                <span>{item?.meta.uploadStats.percentage}%</span><br />
                                                <span className="xcn-stats-card-footer text-muted mt-auto px-3 bg-transparent">Synced on: {formatDate(item?.meta.uploadStats.syncedOn)}</span>
                                            </div>
                                            : "N/A"}
                                    </td>
                                    <td className="text-muted fst-italic">
                                        {
                                            item?.meta?.loginTimes?.length > 0
                                                ? formatDate(item?.meta?.loginTimes?.[item?.meta?.loginTimes?.length - 1])
                                                : "N/A"}
                                    </td>
                                    <td>
                                        {
                                            item?.meta?.logoutTimes?.length > 0
                                                ? formatDate(item?.meta?.logoutTimes?.[item?.meta?.logoutTimes?.length - 1])
                                                : "N/A"}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={12} className="text-center">
                                    No Data Available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            )}
        </>
    );
};

export default DataTable;
