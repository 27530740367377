import {
  faBuilding,
  faChalkboard,
  faChalkboardTeacher,
  faHome,
  faTimesCircle,
  faLocationPin,
  faChartLine,
  faBook,
  faFilter,
  faFileLines,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons";
import IRouter from "../interfaces/IRouter.interface";
import ExamIndex from "../views/private/Exams/Exam.index";
import StatisticsIndex from "../views/private/Statitics/Statitics.index";
import AllExam from "../views/private/Dashboard/Exam/AllExam";
import ExamById from "../views/private/Dashboard/Exam/ExamById";
import CreateInvigilators from "../views/private/Dashboard/Flow/CreateInvigilators";
import InvigilatorCenterTable from "../views/private/Dashboard/Flow/InvigilatorCenterTable";
import InvigilatorData from "../views/private/Dashboard/Flow/InvigilatorData";
import MappingColumnIds from "../views/private/Dashboard/Flow/MappingColumnIds";
import UploadExamShiftCSV from "../views/private/Dashboard/Flow/UploadExamShiftCSV";
import CSRReport from "../views/private/CSRReport";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import FiltersIndex from "../views/private/Filters/Filters.index";
import InvigilatorStatsIndex from "../views/private/InvigilatorStats/InvigilatorStats.index";

export const baseUrl: string = "/dashboard";

const dashboardRoutes: IRouter[] = [
  {
    path: "statistics",
    element: <StatisticsIndex />,
    navbarShow: true,
    activeIcon: faChartLine,
    name: "Statistics",
  },
  {
    path: "csr",
    element: <CSRReport />,
    navbarShow: true,
    activeIcon: faFileLines,
    name: "Cen Reports",
  },
  {
    path: "exam",
    element: <ExamIndex />,
    navbarShow: true,
    activeIcon: faBook,
    name: "Exams",
  },
  {
    path: "filters",
    element: <FiltersIndex />,
    navbarShow: true,
    activeIcon: faFilter,
    name: "Filters",
  },
  {
    path: "exam/:examId",
    element: <ExamById />,
    navbarShow: false,
    activeIcon: faBook,
    name: " ",
  },
  {
    path: "upload/:examId/:shiftId",
    element: <UploadExamShiftCSV />,
    navbarShow: false,
    activeIcon: faBook,
    name: " ",
  },
  {
    path: "mapping/:examId/:shiftId",
    element: <MappingColumnIds />,
    navbarShow: false,
    activeIcon: faBook,
    name: " ",
  },
  {
    path: "createInvigilators/:examId/:shiftId",
    element: <CreateInvigilators />,
    navbarShow: false,
    activeIcon: faBook,
    name: " ",
  },
  {
    path: "invigilatorcentertable/:examId/:shiftId",
    element: <InvigilatorCenterTable />,
    navbarShow: false,
    activeIcon: faBook,
    name: " ",
  },
  {
    path: "invigilatordata/:examId/:shiftId",
    element: <InvigilatorData />,
    navbarShow: false,
    activeIcon: faBook,
    name: " ",
  },
  {
    path: "invigilator-stats",
    element: <InvigilatorStatsIndex />,
    navbarShow: true,
    activeIcon: faChartBar,
    name: "Invigilator Stats",
  },
];

export default dashboardRoutes;
